<template>
  <div id="points">
    <tmpl_header />

    <div class="container">
      <sidebar act="points" />

      <div class="main">
        <section class="card">
          <div class="total">
            <label>积分余额</label>
            <strong>0</strong>
          </div>

          <ul class="thead">
            <li>时间</li>
            <li>积分变动</li>
            <li>积分有效期</li>
            <li>详细说明</li>
          </ul>

          <div class="tbody">
            <div class="empty">暂无积分记录</div>
          </div>
        </section>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>
<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/info") },
      });

    api.all([this.loadData()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadData: function () {
      return api.post(apis.center.info, {}).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./points.less");
</style>
